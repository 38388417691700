import Service from "./Service";
import {isEmpty} from 'lodash';
const baseurl = '/api/asignacion-areas';
export default {
    index() {
        return Service.get(`${baseurl}`);
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    async list(search = '',pagination = {}) {
        let url = `${baseurl}/listar?`;

        url += search !== '' ? `s=${search}` : '';

        url += (!isEmpty(pagination))
            ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}`
            : '';
        return Service.get(url);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
    showByDate(date){
        return Service.get(`${baseurl}/listar`, {
           params : {
            date : date
           }
        });
    },
}
